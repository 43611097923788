/* You can add global styles to this file, and also import other style files */
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@font-face {
    font-family: "Rubik";
    src: local("Rubik"), url(./assets/fonts/Rubik/Rubik-VariableFont_wght.ttf) format("truetype");
}
.header1 {
font-family: 'Rubik';font-size: 32px;font-weight:bold;
}

.header2 {
font-family: 'Rubik';font-size: 20px;font-weight:medium;
}

.button {
font-family: 'Rubik';font-size: 16px;font-weight:medium;
}

.para2 {
font-family: 'Rubik';font-size: 16px;font-weight:regular;
}

.para1 {
font-family: 'Rubik';font-size: 14px;font-weight:regular;
}

.headingColor {
    color: #282C40;
}
.subheadingColor {
    color: #696F79;
}
.subheadingColor1 {
    color: #C4C4C4;
}

.ff {
    font-family: 'Rubik'
}

body{
    font-family: 'Rubik';
}
.padClass{
    padding: 0;
}
p,span,h1,h2,h3,h4,h5,h6,a,label,td,th,tr,button{
    font-family: 'Rubik' !important;
}

[disabled]
{
    pointer-events: all!important;
    cursor: not-allowed!important;
}
.master-container{
    margin-top: 30px;
}
.example-container {
    height: 100%;
    width: auto;
    }

.float_right{
    float: right !important;
}

.white_bg{
    background-color: white !important;
    color: black;
}

.input_title{
    font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
/* identical to box height */

display: flex;
align-items: center;

color: #696F79;
}

title_topic{
    font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 150%;
/* identical to box height, or 30px */

display: flex;
align-items: center;

color: #1F1837;
}
.full-width{
    width: 100% !important;
}
.bold_title{
    font-family: 'Rubik';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 28px;

color: rgba(0, 0, 0, 0.8);

}
.paddingClass{
    padding: 5px !important;
}

.padBottom{
    padding-bottom: 5px;

}
.nextButton{
    width: 300px !important;
}
.bottom-class{
    position: fixed;
    bottom: 0;
    background-color: white;
    padding: 10px;
   }

.centerClass{
    vertical-align: middle;
}
.marginLeft{
    margin-left: 20px !important;
}

.history-class{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    display: flex;
    align-items: center;
    line-height: 150%;
    margin-left: 10px;
    color: #6E668A;
    text-decoration: none;
  }

  .rate-history{
    margin-top: 10px;

  }
  .pointer_class{
    cursor: pointer;
  }

  .back_title{
    display: flex !important;
    align-items: center !important;
    font-family: 'Rubik'!important;

font-style: normal !important;
font-weight: bold !important;
font-size: 20px !important;
line-height: 150%;
color: #3B325F !important;
cursor: pointer;
  }
  .right_back{
    margin-right: 5px;
    font-size: 24px !important;
    cursor: pointer;
  }


  .f500{
    font-weight: 500 !important;
}
.fbold{
    font-weight: bold !important;
}

.greyColor{
    color: #606069 !important;

}

.font20{
font-size: 20px !important;
}

.colorRed{
    color: red;
}


th.mat-header-cell {
    text-align: center !important;
  }

.searchButton{
    height: 40px !important;
    margin-left: -90px !important;
    margin-top: 15px !important;
    z-index: 100 !important;
    border-radius: 4px !important;
}



.searchIcon{
    margin-top: 25px !important;
    margin-right: -40px !important;
    z-index: 100 !important;
}
.roundSquare{
border-radius: 5px !important;
}
.text-indent{
    text-indent: 40px !important;
}

.prepx{
    font-size: 15px !important;
}

.cursor_pointer{
    cursor: pointer;
}   

 .cdk-overlay-container {
    z-index: 10000 !important;
    }

    ::ng-deep .cdk-overlay-container{
        z-index: 1200 !important;
      }


      @media (min-width:1281px) {
        .emailModal {
            width:500px;
            height: 100%;
             z-index: 1500;
            position: fixed;
            top: 0px;
            right: 0px;
            background: white;
            overflow: scroll;
        }
        }

.input_width{
    width: 100%;
}

.input_top_title{
    font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
color: #696F79;
}

.text_input_css{
    height: 52px !important;
    border: 2px solid #dfdfdf !important;
border-radius: 4px !important;
}



  .toast-title{
    color: rgb(255, 255, 255) !important;
    font-size: 14px !important;


    font-weight: 300;
    letter-spacing:0.2px;


    font-family: Rubik;


  }

/* .toast-container {


} */

/* .toast-info
{
    background-color: yellow;
    color: green;
} */


  .toast-success, .toast-error, .toast-warning, .toast-info {
    border-radius: 10px !important;

  }




/*
  .toast-info {


  } */



  .close{

    font-size: 25px !important;
    font-weight: 300 !important;
    line-height: 1 !important;

    text-shadow: 0 1px 0 #808080 !important;



  }

  /* To remove increment and decrement from input type number */
  .rid::-webkit-inner-spin-button,
.rid::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}


.shad{
    height: 52px;
    background: #4070f4;
    border-radius: 10px;
    width: 90%;
  }
  .shad:hover{
    background: linear-gradient(0deg, #0D39AF, #0D39AF), #4070F4;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);

  }


  .input-group-text
  {
    background-color: #dfdfdf!important;
  }



.flex_center{
    display: flex;
    justify-content: center;
}
.req{
    color: #F03738;
font-family: 'Rubik';
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.main-content-container{
  height:90vh; width:100%; overflow-y:scroll; padding-bottom:10vh;
}
::-webkit-scrollbar {
    width: 7px;
    overflow-y: scroll;
    background: #EAEAEA;
    box-shadow: inset 0 0 4px #EAEAEA;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #9F9F9F;
    border-radius: 10px;
  }

.custom-dialog{
    .mat-dialog-container{
        border-radius: 15px;
    }

    &.rm-padding{
        .mat-dialog-container{
            padding: 0 !important;
        }
    }
}


::-webkit-scrollbar {
    width: 6px;
}

/* Track styles */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle styles */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Horizontal scrollbar */
::-webkit-scrollbar:horizontal {
    height: 6px;
}

/* Handle styles for horizontal scrollbar */
::-webkit-scrollbar-thumb:horizontal {
    background: #888;
    border-radius: 4px;
}

/* Handle on hover for horizontal scrollbar */
::-webkit-scrollbar-thumb:horizontal:hover {
    background: #555;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.5;
}

.cdk-overlay-dark-backdrop {
    background: #2f3a4a;
}

.mat-input-element {
    color: #636363;
  }